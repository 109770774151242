import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { CONVERSATIONS_URL, MARK_MESSAGE_AS_READ_URL } from '../../constants/Urls';
import './Messages.css';
import {
    setCurrentScreen,
    getCurrentScreen,
    setFirstPageState,
    getFirstPageState,
} from '../../helpers/ScreenState';

const Messages = () => {
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sendingMessageIds, setSendingMessageIds] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageLoading, setImageLoading] = useState({});
    const [expandedImage, setExpandedImage] = useState(null);
    const [expandedImageLoading, setExpandedImageLoading] = useState(false);

    const listRef = useRef(null);
    const lastMessageTimestamp = useRef(null);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { conversationId, userId } = location.state || {};
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
        fetchMessages();
        const intervalId = setInterval(fetchMessages, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const fetchMessages = async (loadMore = false) => {
        if (loading) return;
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const url = conversationId
                ? `${CONVERSATIONS_URL}?conversation_id=${conversationId}&limit=10&offset=${loadMore ? offset : 0}`
                : `${CONVERSATIONS_URL}?limit=10&offset=${loadMore ? offset : 0}`;

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 401) {
                alert(t('Session Expired'), t('Your session has expired. Please log in again.'));
                navigate('/login-form');
                return;
            }

            const data = response.data;
            if (loadMore) {
                setMessages((prevMessages) => [...prevMessages, ...data]);
                setOffset((prevOffset) => prevOffset + 10);
            } else {
                setMessages(data);
                setOffset(10);
            }
            setHasMore(data.length === 10);
            markMessagesAsRead(data);
        } catch (error) {
            alert(t('Error'), t('Failed to fetch messages. Please try again.'));
        } finally {
            setLoading(false);
        }
    };

    const markMessagesAsRead = async (messages) => {
        const unreadMessages = messages.filter((message) => !message.is_read && message.sender_id !== userId);

        for (const message of unreadMessages) {
            try {
                const token = localStorage.getItem('token');
                await axios.put(
                    MARK_MESSAGE_AS_READ_URL,
                    { message_id: message.id },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (error) {
                console.error('Failed to mark message as read:', error);
            }
        }
    };

    const sendMessage = async () => {
        if (messageText.trim() === '' && !selectedImage) return;

        const now = Date.now();
        if (lastMessageTimestamp.current && now - lastMessageTimestamp.current < 1500) return;
        lastMessageTimestamp.current = now;

        const tempId = Math.random().toString();
        const newMessage = {
            id: tempId,
            sender_id: userId,
            content: messageText,
            timestamp: new Date().toISOString(),
            is_read: false,
            sender_name: 'You',
            image_url: selectedImage ? selectedImage.uri : null,
            replying_to: replyingTo ? replyingTo.id : null,
            reply_to_content: replyingTo ? replyingTo.content : null,
            reply_to_sender_username: replyingTo ? replyingTo.sender_username : null,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setSendingMessageIds((prev) => [...prev, tempId]);
        setMessageText('');
        setReplyingTo(null);
        setSelectedImage(null);

        const formData = new FormData();
        formData.append('recipient_id', userId);
        formData.append('content', messageText);
        formData.append('reply_to', replyingTo ? replyingTo.id : null);
        if (selectedImage) {
            formData.append('image', selectedImage);
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(CONVERSATIONS_URL, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            const data = response.data;
            setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === tempId ? data : msg)));
            setSendingMessageIds((prev) => prev.filter((id) => id !== tempId));
        } catch (error) {
            alert(t('Error'), t('Failed to send message. Please try again.'));
            setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== tempId));
        }
    };

    const handleLikeMessage = async (messageId, isLiked) => {
        // Optimistically update the UI
        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === messageId
                    ? { ...message, like_count: isLiked ? message.like_count - 1 : message.like_count + 1, isLiked: !isLiked }
                    : message
            )
        );

        const url = isLiked ? `${CONVERSATIONS_URL}/unlike` : `${CONVERSATIONS_URL}/like`;

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                url,
                { message_id: messageId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error('Failed to like/unlike the message');
            }
        } catch (error) {
            // Revert the optimistic update on error
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === messageId
                        ? { ...message, like_count: isLiked ? message.like_count + 1 : message.like_count - 1, isLiked }
                        : message
                )
            );
            alert(t('Error') + ': ' + error.message);
        }
    };

    const handleReplyContainerClick = async (replyToId) => {
        const messageIndex = messages.findIndex((msg) => msg.id === replyToId);
        if (messageIndex !== -1) {
            listRef.current.scrollToIndex({ index: messageIndex, behavior: 'smooth' });
        } else {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${CONVERSATIONS_URL}?message_id=${replyToId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data;
            setMessages((prevMessages) => [...prevMessages, data]);
            setTimeout(() => {
                const index = messages.findIndex((msg) => msg.id === replyToId);
                if (index !== -1) {
                    listRef.current.scrollToIndex({ index, behavior: 'smooth' });
                }
            }, 100);
        }
    };

    const renderMessageItem = (item) => {
        const isCurrentUser = item.sender_id != userId;
        const isSending = sendingMessageIds.includes(item.id);

        return (
            <div
                key={item.id}
                className={`message-container ${isCurrentUser ? 'current-user-message' : 'other-user-message'}`}
            >
                {item.reply_to_content && (
                    <div className="reply-container" onClick={() => handleReplyContainerClick(item.reply_to)}>
                        <p className="reply-text">
                            {t('Replying to')} {item.reply_to_sender_username}: {item.reply_to_content}
                        </p>
                    </div>
                )}
                <p className="message-user">{item.sender_name}</p>
                <p className="message-text">{item.content}</p>
                {item.image_url && (
                    <div className="image-container" onClick={() => handleImageExpand(item.image_url)}>
                        {imageLoading[item.id] && <div className="image-loader">Loading...</div>}
                        <img
                            src={item.image_url}
                            alt="Message"
                            className="message-image"
                            onLoadStart={() => setImageLoading((prev) => ({ ...prev, [item.id]: true }))}
                            onLoadEnd={() => setImageLoading((prev) => ({ ...prev, [item.id]: false }))}
                        />
                    </div>
                )}
                <div className="message-timestamp-container">
                    <p className="message-timestamp">{new Date(item.timestamp).toLocaleString()}</p>
                    <div className="message-actions">
                        {!isCurrentUser && (
                            <i
                                onClick={() => handleLikeMessage(item.id, item.isLiked)}
                                className={`fa${item.isLiked ? 's' : 'r'} fa-heart likeButton`}
                            ></i>
                        )}
                        {isCurrentUser && item.like_count > 0 && <i className="fas fa-heart likeButton"></i>}
                        {isSending ? (
                            <div className="loader">Sending...</div>
                        ) : (
                            <i className={`${item.is_read ? 'fas fa-check-double' : ' fas fa-check'}`}></i>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const handleLoadMore = () => {
        if (hasMore && !loading) {
            fetchMessages(true);
        }
    };

    const handleScroll = (event) => {
        const position = event.target.scrollTop;
        setFirstPageState(position <= 0);
    };

    const selectImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const removeSelectedImage = () => {
        setSelectedImage(null);
    };

    const handleImageExpand = (imageUrl) => {
        setExpandedImageLoading(true);
        setExpandedImage(imageUrl);
    };

    return (
        <div className="messages-container">
            <div className="messages-list" ref={listRef} onScroll={handleScroll}>
                {messages.map((item) => renderMessageItem(item))}
                {loading && <div className="loader">Loading...</div>}
            </div>
            {replyingTo && (
                <div className="reply-banner">
                    <p className="reply-banner-text">
                        {t('Replying to')} {replyingTo.sender_username}: {replyingTo.content}
                    </p>
                    <button onClick={() => setReplyingTo(null)}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            )}
            {selectedImage && (
                <div className="selected-image-container">
                    <img src={URL.createObjectURL(selectedImage)} alt="Selected" className="selected-image" />
                    <button onClick={removeSelectedImage} className="remove-image-button">
                        <i className="fas fa-times-circle"></i>
                    </button>
                </div>
            )}
            <div className="input-container">
                <input type="file" onChange={selectImage} className="image-input" />
                <input
                    type="text"
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    placeholder={t('Type a message')}
                    className="message-input"
                />
                <button onClick={sendMessage} className="send-button">
                    <i className="fab fa-telegram-plane"></i>
                </button>
            </div>
            {expandedImage && (
                <div className="modal-background">
                    <button onClick={() => setExpandedImage(null)} className="modal-close-button">
                        <i className="fas fa-times"></i>
                    </button>
                    {expandedImageLoading && <div className="modal-spinner">Loading...</div>}
                    <img
                        src={expandedImage}
                        alt="Expanded"
                        className="expanded-image"
                        onLoadStart={() => setExpandedImageLoading(true)}
                        onLoadEnd={() => setExpandedImageLoading(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default Messages;
