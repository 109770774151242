export const API_BASE_URL = 'https://givents.by/api';
export const LOGIN_URL = `${API_BASE_URL}/login`;
export const LOGOUT_URL = `${API_BASE_URL}/logout`;
export const EVENTS_URL = `${API_BASE_URL}/events`;
export const REGISTRATION_URL = `${API_BASE_URL}/register`;
export const NOTIFICATIONS_URL = `${API_BASE_URL}/notifications`;
export const NOTIFICATIONS_COUNT_URL = `${API_BASE_URL}/notifications/count`;
export const FORM_URL = `${API_BASE_URL}/form`;
export const INVITE_FORM_URL = `${API_BASE_URL}/inviteForm`;
export const RESPONSES_URL = `${API_BASE_URL}/responses`;
export const TASKS_URL = `${API_BASE_URL}/tasks`;
export const TEAM_URL = `${API_BASE_URL}/team`;
export const TASK_ORDER_URL = `${API_BASE_URL}/taskOrder`;
export const TEMPLATES_URL = `${API_BASE_URL}/templates`;
export const TEMPLATE_LIKES_URL = `${API_BASE_URL}/templateLikes`;
export const TEMPLATE_DETAILS_URL = `${API_BASE_URL}/templateDetails`;
export const PROFILE_URL = `${API_BASE_URL}/profile`;
export const UPLOAD_PROFILE_PICTURE_URL = `${PROFILE_URL}/picture`;
export const INVITATION_URL = `${API_BASE_URL}/event_form.html`;
export const CONVERSATIONS_URL = `${API_BASE_URL}/messages`;
export const MARK_MESSAGE_AS_READ_URL = `${API_BASE_URL}/messages/read`;
export const USERS_URL = `${API_BASE_URL}/users`;
export const CHANGEPASSWORD_URL = `${API_BASE_URL}/changePassword`;
export const FAVORITE_URL = `${API_BASE_URL}/favouriteUsers`;
export const SAVE_DEVICE_TOKEN_URL = `${API_BASE_URL}/save_device_token`;
export const RESET_PASSWORD_URL = `${API_BASE_URL}/resetPassword`;
export const CONTACT_US_URL = `${API_BASE_URL}/contactUs`;
export const CHECKLIST_RESPONSES_URL = `${API_BASE_URL}/guest-checklist-responses`;
