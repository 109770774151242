import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Settings.css';
import ContactUsModal from '../contactUs/ContactUsModal';

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showContactUs, setShowContactUs] = useState(false);

  useEffect(() => {
    // Any necessary initialization can be done here
  }, []);

  const logOut = async () => {
    // Implement logout logic here
    navigate('/login-form');
  };

  const menuBlocks = [
    {
      title: t('Account Settings'),
      items: [
        { title: t('Profile'), icon: 'fas fa-user', screen: '/profile' },
        { title: t('Change Password'), icon: 'fas fa-lock', screen: '/change-password' },
        { title: t('Language'), icon: 'fas fa-language', screen: '/language' },
      ],
    },
    {
      title: t('General'),
      items: [
        { title: t('Users'), icon: 'fas fa-users', screen: '/users' },
        { title: t('Conversations'), icon: 'fas fa-comments', screen: '/conversations' },
        { title: t('All Tasks'), icon: 'fas fa-tasks', screen: '/tasks' },
        { title: t('Notifications'), icon: 'fas fa-bell', screen: '/notifications' },
      ],
    },
    {
      title: t('Need Help?'),
      items: [
        { title: t('Help'), icon: 'fas fa-question-circle', screen: '/help' },
        { title: t('Contact Us'), icon: 'fas fa-comment-alt', screen: () => setShowContactUs(true) },
      ],
    },
  ];

  const renderMenuBlock = (block) => (
    <div key={block.title} className="menu-block">
      <h2 className="block-title">{block.title}</h2>
      {block.items.map((item, index) => (
        <div key={index} className="item" onClick={() => typeof item.screen === 'function' ? item.screen() : navigate(item.screen)}>
          <div className="icon-container">
            <i className={item.icon}></i>
          </div>
          <span className="text">{item.title}</span>
          <i className="fas fa-chevron-right chevron-icon"></i>
        </div>
      ))}
    </div>
  );

  return (
    <div className="settings-container">
      {menuBlocks.map(renderMenuBlock)}
      <div className="menu-block">
        <h2 className="block-title">{t('Logout')}</h2>
        <div className="item" onClick={logOut}>
          <div className="icon-container">
            <i className="fas fa-sign-out-alt logout-icon"></i>
          </div>
          <span className="text logout-text">{t('Logout')}</span>
          <i className="fas fa-chevron-right chevron-icon"></i>
        </div>
      </div>
      <ContactUsModal show={showContactUs} handleClose={() => setShowContactUs(false)} />
    </div>
  );
};

export default Settings;
