import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { USERS_URL, FAVORITE_URL } from '../../constants/Urls';
import './Users.css';
import countriesData from '../../resources/countries.json';
import specializationsData from '../../resources/specializations.json';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';

const Users = ({ event }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [isFilterModalVisible, setFilterModalVisible] = useState(false);
  const [isSpecialistOnly, setIsSpecialistOnly] = useState(false);
  const [isFavoritesOnly, setIsFavoritesOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [tempFilters, setTempFilters] = useState({
    country: '',
    city: '',
    specializations: [],
    isSpecialistOnly: false,
    isFavoritesOnly: false,
  });
  const [lastAppliedFilters, setLastAppliedFilters] = useState({
    country: '',
    city: '',
    specializations: [],
    isSpecialistOnly: false,
    isFavoritesOnly: false,
  });
  const PAGE_SIZE = 10;
  const currentPageRef = useRef(1);
  const hasMoreRef = useRef(true);

  useEffect(() => {
    loadUsers(true);
  }, [
    selectedCountry,
    selectedCity,
    searchTerm,
    isSpecialistOnly,
    isFavoritesOnly,
    selectedSpecializations,
  ]);

  useEffect(() => {
    if (tempFilters.country) {
      const country = countriesData.find((c) => c.name === tempFilters.country);
      setCities(country ? country.cities : []);
    } else {
      setCities([]);
    }
    setTempFilters((prevState) => ({ ...prevState, city: '' }));
  }, [tempFilters.country]);

  const loadUsers = async (reset = false) => {
    if (reset) {
      setUsers([]);
      currentPageRef.current = 1;
      hasMoreRef.current = true;
    }
    setIsLoading(true);

    let url = `${USERS_URL}?limit=${PAGE_SIZE}&offset=${reset ? 0 : users.length}`;
    if (selectedCountry) {
      url += `&country=${selectedCountry}`;
    }
    if (selectedCity) {
      url += `&city=${selectedCity}`;
    }
    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }
    if (isSpecialistOnly) {
      url += '&isSpecialist=true';
    }
    if (isFavoritesOnly) {
      url += '&isFavorite=true';
    }
    if (selectedSpecializations.length > 0) {
      url += `&specializations=${selectedSpecializations.join(',')}`;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      if (reset) {
        setUsers(data);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...data]);
      }
      setHasMore(data.length === PAGE_SIZE);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFavorite = async (userId, isFavorite) => {
    try {
      const method = isFavorite ? 'DELETE' : 'POST';
      const token = localStorage.getItem('token');
      const response = await axios({
        method,
        url: FAVORITE_URL,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { favorite_user_id: userId },
      });

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, is_favorite: !isFavorite } : user
          )
        );
      } else {
        console.error('Failed to toggle favorite status');
      }
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  const loadMoreUsers = () => {
    if (!isLoading && hasMore) {
      loadUsers();
    }
  };

  const openConversation = (user) => {
    navigate(`/messages/${user.id}`);
  };

  const addSpecializationField = () => {
    setTempFilters((prevState) => ({
      ...prevState,
      specializations: [...prevState.specializations, ''],
    }));
  };

  const removeSpecializationField = (index) => {
    setTempFilters((prevState) => ({
      ...prevState,
      specializations: prevState.specializations.filter((_, i) => i !== index),
    }));
  };

  const updateSpecialization = (value, index) => {
    setTempFilters((prevState) => ({
      ...prevState,
      specializations: prevState.specializations.map((spec, i) =>
        i === index ? value : spec
      ),
    }));
    setSelectedSpecializations(tempFilters.specializations);
  };

  const applyFilters = () => {
    setSelectedCountry(tempFilters.country);
    setSelectedCity(tempFilters.city);
    setSelectedSpecializations(tempFilters.specializations.filter((spec) => spec));
    setIsSpecialistOnly(tempFilters.isSpecialistOnly);
    setIsFavoritesOnly(tempFilters.isFavoritesOnly);
    setLastAppliedFilters(tempFilters);
    setFilterModalVisible(false);
    loadUsers(true);
  };

  const clearFilters = () => {
    setTempFilters({
      country: '',
      city: '',
      specializations: [],
      isSpecialistOnly: false,
      isFavoritesOnly: false,
    });
    setSelectedCountry('');
    setSelectedCity('');
    setSelectedSpecializations([]);
    setIsSpecialistOnly(false);
    setIsFavoritesOnly(false);
    setFilterModalVisible(false);
    loadUsers(true);
  };

  const closeFilterModal = () => {
    setTempFilters(lastAppliedFilters);
    setFilterModalVisible(false);
  };

  const handleFilterRemove = (filterType, value) => {
    if (filterType === 'country') {
      setSelectedCountry('');
    } else if (filterType === 'city') {
      setSelectedCity('');
    } else if (filterType === 'specialization') {
      setSelectedSpecializations((prev) => prev.filter((spec) => spec !== value));
    } else if (filterType === 'specialistOnly') {
      setIsSpecialistOnly(false);
    } else if (filterType === 'favoritesOnly') {
      setIsFavoritesOnly(false);
    }
    loadUsers(true);
  };

  return (
    <div className="users-container">
      <div className="search-container">
        <input
          type="text"
          placeholder={t('Search by name or username')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && loadUsers(true)}
        />
        <button onClick={() => setFilterModalVisible(true)}>
          {t('Filters')}
        </button>
      </div>
      <div className="selected-filters">
        {selectedCountry && (
          <span className="filter-tag" onClick={() => handleFilterRemove('country')}>
            {t('Country')}: {selectedCountry} ×
          </span>
        )}
        {selectedCity && (
          <span className="filter-tag" onClick={() => handleFilterRemove('city')}>
            {t('City')}: {selectedCity} ×
          </span>
        )}
        {selectedSpecializations.map((spec) => (
          <span
            key={spec}
            className="filter-tag"
            onClick={() => handleFilterRemove('specialization', spec)}
          >
            {t('Specialization')}: {spec} ×
          </span>
        ))}
        {isSpecialistOnly && (
          <span
            className="filter-tag"
            onClick={() => handleFilterRemove('specialistOnly')}
          >
            {t('Show Specialists')} ×
          </span>
        )}
        {isFavoritesOnly && (
          <span
            className="filter-tag"
            onClick={() => handleFilterRemove('favoritesOnly')}
          >
            {t('Show Favorites')} ×
          </span>
        )}
      </div>
      <div className="users-list">
        {isLoading ? (
          <div className="loader">{t('Loading...')}</div>
        ) : (
          users.map((user) => (
            <div
              key={user.id}
              className="user-item"
              onClick={() => navigate(`/profile/${user.id}`)}
            >
              <img
                src={user.profile_picture || require('../../assets/default-profile.png')}
                alt="Profile"
                className="profile-picture"
              />
              <div className="user-details">
                <p>{`${user.name} (@${user.username})`}</p>
                <p>
                  {user.country && user.city
                    ? `${user.country}, ${user.city}`
                    : user.country || user.city}
                </p>
                {user.specializations && (
                  <p>
                    {t('Specializations')}: {user.specializations}
                  </p>
                )}
              </div>
              <div className="icon-buttons">
                <button
                  onClick={() => toggleFavorite(user.id, user.is_favorite)}
                  className="favorite-button"
                >
                  <i
                    className={`fa${user.is_favorite ? 's' : 'r'} fa-star`}
                    style={{ color: user.is_favorite ? '#FFD700' : '#555' }}
                  ></i>
                </button>
                <button onClick={() => openConversation(user)} className="message-button">
                  <i className="fas fa-envelope"></i>
                </button>
              </div>
            </div>
          ))
        )}
        {hasMoreRef.current && !isLoading && (
          <div className="load-more">
            <button onClick={loadMoreUsers}>{t('Load More')}</button>
          </div>
        )}
        {!isLoading && users.length === 0 && (
          <div className="no-users">{t('No users available.')}</div>
        )}
      </div>
      <Modal show={isFilterModalVisible} onHide={closeFilterModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('Filter Users')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>{t('Select Country')}</Form.Label>
              <Form.Control
                as="select"
                value={tempFilters.country}
                onChange={(e) => {
                  setTempFilters((prevState) => ({
                    ...prevState,
                    country: e.target.value,
                    city: '',
                  }));
                  setSelectedCountry(e.target.value);
                }}
              >
                <option value="">{t('Select a country')}</option>
                {countriesData.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Select City')}</Form.Label>
              <Form.Control
                as="select"
                value={tempFilters.city}
                onChange={(e) => {
                  setTempFilters((prevState) => ({ ...prevState, city: e.target.value }));
                  setSelectedCity(e.target.value);
                }}
                disabled={!tempFilters.country}
              >
                <option value="">{t('Select a city')}</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                label={t('Show Specialists')}
                checked={tempFilters.isSpecialistOnly}
                onChange={(e) => {
                  setTempFilters((prevState) => ({
                    ...prevState,
                    isSpecialistOnly: e.target.checked,
                  }));
                  setIsSpecialistOnly(e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                label={t('Show Favorites')}
                checked={tempFilters.isFavoritesOnly}
                onChange={(e) => {
                  setTempFilters((prevState) => ({
                    ...prevState,
                    isFavoritesOnly: e.target.checked,
                  }));
                  setIsFavoritesOnly(e.target.checked);
                }}
              />
            </Form.Group>
            {tempFilters.isSpecialistOnly &&
              tempFilters.specializations.map((specialization, index) => (
                <Form.Group key={index} className="specialization-group">
                  <Form.Control
                    as="select"
                    value={specialization}
                    onChange={(e) => {
                      updateSpecialization(e.target.value, index);
                      setSelectedSpecializations(tempFilters.specializations);
                    }}
                  >
                    <option value="">{t('Select a specialization')}</option>
                    {specializationsData.map((spec) => (
                      <option key={spec} value={spec}>
                        {spec}
                      </option>
                    ))}
                  </Form.Control>
                  {tempFilters.specializations.length > 1 && (
                    <Button
                      variant="danger"
                      onClick={() => removeSpecializationField(index)}
                    >
                      -
                    </Button>
                  )}
                </Form.Group>
              ))}
            {tempFilters.isSpecialistOnly && (
              <Button onClick={addSpecializationField}>{t('Add Specialization')}</Button>
            )}
            <Button variant="primary" onClick={applyFilters} className="apply-filters-button">
              {t('Apply Filters')}
            </Button>
            <Button variant="danger" onClick={clearFilters} className="clear-filters-button">
              {t('Clear Filters')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Users;
