import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, Navbar, Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TopMenu.css';

const TopMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear any stored tokens or user data here
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <Navbar bg="light" expand="lg" className="top-menu">
      <Container fluid>
        <Navbar.Brand as={Link} to="/events">{t('Givents')}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/events">{t('Events')}</Nav.Link>
            <Nav.Link as={Link} to="/all-tasks">{t('All Tasks')}</Nav.Link>
            <Nav.Link as={Link} to="/users">{t('Users')}</Nav.Link>
            <Nav.Link as={Link} to="/templates">{t('Templates')}</Nav.Link>
            <Nav.Link as={Link} to="/notifications">{t('Notifications')}</Nav.Link>
            <Nav.Link as={Link} to="/messages">{t('Messages')}</Nav.Link>
            <Nav.Link as={Link} to="/profile">{t('Profile')}</Nav.Link>
            <Nav.Link as={Link} to="/settings">{t('Settings')}</Nav.Link>
            <Button variant="outline-danger" onClick={handleLogout} className="ms-2">{t('Logout')}</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopMenu;
