import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { RESPONSES_URL, EVENTS_URL, FORM_URL } from '../../constants/Urls';
import './CreateGuestModal.css';

const CreateGuestModal = ({ showModal, onClose, eventId, guest, responses: initialResponses }) => {
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState([]);
  const [responses, setResponses] = useState({});
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(eventId || null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEvents();
    if (guest) {
      setResponses(
        initialResponses.reduce((acc, { form_field_id, response }) => {
          acc[form_field_id] = Array.isArray(response)
            ? response
            : response.includes(',')
            ? response.split(',')
            : response;
          return acc;
        }, {})
      );
      setSelectedEvent(guest.event_id);
    }
  }, [guest, initialResponses]);

  useEffect(() => {
    if (selectedEvent) {
      fetchInviteForm(selectedEvent);
    }
  }, [selectedEvent]);

  const fetchInviteForm = async (eventId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${FORM_URL}?event_id=${eventId}`);
      if (response.data.success) {
        setFields(response.data.fields);
      } else {
        alert(t('Failed to load form fields'));
      }
    } catch (error) {
      alert(t('Failed to load form fields'));
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(EVENTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvents(response.data);
    } catch (error) {
      alert(t('Error fetching events') + ': ' + error.message);
    }
  };

  const handleInputChange = (value, fieldId) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  const handleCheckboxChange = (value, fieldId) => {
    const updatedResponses = { ...responses };
    if (updatedResponses[fieldId]?.includes(value)) {
      updatedResponses[fieldId] = updatedResponses[fieldId].filter((v) => v !== value);
    } else {
      updatedResponses[fieldId] = [...(updatedResponses[fieldId] || []), value];
    }
    setResponses(updatedResponses);
  };

  const handleRadioChange = (value, fieldId) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  const validateFields = () => {
    for (let field of fields) {
      const value = responses[field.id];
      if (field.required === 1) {
        if (
          field.field_type === 'text' ||
          field.field_type === 'email' ||
          field.field_type === 'phone' ||
          field.field_type === 'name'
        ) {
          if (!value || value.trim() === '') {
            return {
              valid: false,
              message: `${t(field.field_label)} is required.`,
            };
          }
        } else if (field.field_type === 'checkbox') {
          if (!value || value.length === 0) {
            return {
              valid: false,
              message: `At least one option for ${t(field.field_label)} is required.`,
            };
          }
        } else if (field.field_type === 'radio') {
          if (!value) {
            return {
              valid: false,
              message: `An option for ${t(field.field_label)} is required.`,
            };
          }
        }
      }
    }
    return { valid: true, message: '' };
  };

  const handleSubmit = async () => {
    if (!selectedEvent) {
      alert(t('Please select an event'));
      return;
    }
    const { valid, message } = validateFields();
    if (!valid) {
      alert(message);
      return;
    }

    const token = localStorage.getItem('token');
    const participantId = guest ? guest.participant_id : generateUUIDv4();
    const lang = i18n.language;

    const payload = {
      event_id: selectedEvent,
      participant_id: participantId,
      responses: responses,
      lang,
    };

    try {
      const response = await axios({
        method: guest ? 'PUT' : 'POST',
        url: RESPONSES_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });
      if (response.data.success) {
        alert(guest ? t('Guest updated successfully') : t('Guest added successfully'));
        onClose();
      } else {
        alert(t('Failed to submit responses'));
      }
    } catch (error) {
      alert(t('Failed to submit responses'));
    }
  };

  const generateUUIDv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{guest ? t('Edit Guest') : t('Add Guest')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t('Event')}</Form.Label>
            <Form.Control
              as="select"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              disabled={!!guest}
            >
              <option value="">{t('Select Event')}</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {loading ? (
            <div className="loader">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading...')}</span>
              </Spinner>
            </div>
          ) : (
            fields.map((field) => (
              <Form.Group key={field.id}>
                <Form.Label>
                  {t(field.field_label)}
                  {field.required === 1 && <span className="text-danger"> *</span>}
                </Form.Label>
                {field.field_type === 'text' ||
                field.field_type === 'email' ||
                field.field_type === 'phone' ||
                field.field_type === 'name' ? (
                  <Form.Control
                    type={field.field_type}
                    placeholder={t(field.field_label)}
                    value={responses[field.id] || ''}
                    onChange={(e) => handleInputChange(e.target.value, field.id)}
                  />
                ) : field.field_type === 'checkbox' ? (
                  JSON.parse(field.field_options).map((option, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={option}
                      checked={responses[field.id]?.includes(option) || false}
                      onChange={() => handleCheckboxChange(option, field.id)}
                    />
                  ))
                ) : field.field_type === 'radio' ? (
                  JSON.parse(field.field_options).map((option, index) => (
                    <Form.Check
                      key={index}
                      type="radio"
                      label={option}
                      name={`radio-${field.id}`}
                      checked={responses[field.id] === option}
                      onChange={() => handleRadioChange(option, field.id)}
                    />
                  ))
                ) : null}
              </Form.Group>
            ))
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {guest ? t('Update') : t('Add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGuestModal;
