import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { RESPONSES_URL } from '../../constants/Urls';
import './GuestModal.css';

const GuestModal = ({ showModal, onClose, guest, eventId, onEdit }) => {
  const { t } = useTranslation();
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (guest) {
      setResponses(guest.responses);
      setIsLoading(false);
    }
  }, [guest]);

  const confirmDelete = () => {
    if (window.confirm(t('Are you sure you want to delete this person?'))) {
      deletePerson();
    }
  };

  const deletePerson = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${RESPONSES_URL}?event_id=${eventId}&participant_id=${guest.participant_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert(t('Person deleted successfully.'));
        onClose();
      } else {
        alert(t('Failed to delete person. Please try again.'));
      }
    } catch (error) {
      alert(t('An error occurred while deleting person. Please try again.'));
    }
  };

  const editPerson = () => {
    onClose();
    onEdit(guest);
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('Guest Details')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div>{t('Loading...')}</div>
        ) : (
          responses.map((response, index) => (
            <div key={index} className="response-container">
              <div className="response-label">
                <strong>{t(response.field_label)}:</strong>
              </div>
              <div className="response-value">
                {Array.isArray(response.response)
                  ? response.response.join(', ')
                  : response.response}
              </div>
            </div>
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={confirmDelete}>
          {t('Delete Person')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuestModal;
