import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { RESPONSES_URL } from '../../constants/Urls';
import './Guests.css';
import GuestModal from './GuestModal';
import CreateGuestModal from './CreateGuestModal';

const Guests = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [guests, setGuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [showCreateGuestModal, setShowCreateGuestModal] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);

  useEffect(() => {
    loadGuests();
  }, [id]);

  const loadGuests = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${RESPONSES_URL}?event_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setGuests(response.data.responses);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching guests:', error);
      alert(t('Error') + ': ' + error.message);
      setIsLoading(false);
    }
  }, [id, t]);

  const handleViewGuest = (guest) => {
    setSelectedGuest(guest);
    setShowGuestModal(true);
  };

  const handleEditGuest = (guest) => {
    setEditingGuest(guest);
    setShowCreateGuestModal(true);
  };

  const handleAddGuest = () => {
    setEditingGuest(null);
    setShowCreateGuestModal(true);
  };

  const handleInviteForm = () => {
    navigate(`/event/${id}/invite-form`, { state: { event: id } });
  };

  const handleCloseGuestModal = () => {
    setShowGuestModal(false);
    setSelectedGuest(null);
    loadGuests(); // Reload guests after closing modal
  };

  const handleCloseCreateGuestModal = () => {
    setShowCreateGuestModal(false);
    setEditingGuest(null);
    loadGuests(); // Reload guests after closing modal
  };

  if (isLoading) {
    return <div className="loader">{t('Loading...')}</div>;
  }

  return (
    <div className="guests-container">
      <h2>{t('Guests')}</h2>
      <p>{t('Total Registrations', { count: guests.length || 0 })}</p>
      <div className="guests-list">
        {guests.length === 0 ? (
          <div>{t('No people invited.')}</div>
        ) : (
          guests.map((guest) => {
            const nameResponse =
              guest.responses.find((response) => response.field_type === 'name')?.response ||
              'Unnamed';
            return (
              <div key={guest.participant_id} className="guest-item">
                <div className="guest-item-details">
                  <span>{nameResponse}</span>
                </div>
                <button className="guest-item-button" onClick={() => handleViewGuest(guest)}>
                  {t('View')}
                </button>
                <button className="guest-item-button" onClick={() => handleEditGuest(guest)}>
                  {t('Edit')}
                </button>
              </div>
            );
          })
        )}
      </div>
      <button className="invite-button" onClick={handleAddGuest}>
        {t('Add Guest')}
      </button>
      {showGuestModal && (
        <GuestModal
          showModal={showGuestModal}
          onClose={handleCloseGuestModal}
          guest={selectedGuest}
          eventId={id}
        />
      )}
      {showCreateGuestModal && (
        <CreateGuestModal
          showModal={showCreateGuestModal}
          onClose={handleCloseCreateGuestModal}
          eventId={id}
          guest={editingGuest}
          responses={editingGuest?.responses || []}
        />
      )}
    </div>
  );
};

export default Guests;
