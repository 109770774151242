import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { INVITE_FORM_URL, INVITATION_URL } from '../../constants/Urls';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Form.css';
import FormFieldModal from './FormFieldModal';

const Form = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    loadFields();
  }, [id]);

  const loadFields = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${INVITE_FORM_URL}?event_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const sortedFields = response.data.fields.sort(
          (a, b) => a.order_id - b.order_id
        );
        setFields(sortedFields);
      } else {
        alert(t('Failed to fetch form fields.'));
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching form fields:', error);
      alert(t('Error') + ': ' + error.message);
      setIsLoading(false);
    }
  }, [id, t]);

  const handleAddField = () => {
    setEditingField(null);
    setShowFieldModal(true);
  };

  const handleEditField = (field) => {
    setEditingField(field);
    setShowFieldModal(true);
  };

  const handleDeleteField = (fieldId) => {
    if (window.confirm(t('Are you sure you want to delete this field?'))) {
      deleteField(fieldId);
    }
  };

  const deleteField = async (fieldId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${INVITE_FORM_URL}?id=${fieldId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        alert(t('Field deleted successfully'));
        setFields(fields.filter((field) => field.id !== fieldId));
      } else {
        alert(t('Failed to delete field.'));
      }
    } catch (error) {
      console.error('Error deleting field:', error);
      alert(t('Failed to delete field.'));
    }
  };

  const handleCloseFieldModal = () => {
    setShowFieldModal(false);
    setEditingField(null);
    loadFields(); // Reload fields after closing modal
  };

  const updateFieldOrders = async (newOrder) => {
    const promises = newOrder.map(async (item, index) => {
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${INVITE_FORM_URL}?updateOrder`,
          {
            id: item.id,
            order_id: index,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error('Error updating field order:', error);
        alert(t('Failed to update field order.'));
      }
    });

    await Promise.all(promises);
    loadFields();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFields = Array.from(fields);
    const [removed] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removed);

    setFields(reorderedFields);
    updateFieldOrders(reorderedFields);
  };

  const getRegistrationUrl = () => `${INVITATION_URL}?event_id=${id}`;

  const handleGetUrlPress = async () => {
    const url = getRegistrationUrl();
    try {
      await navigator.clipboard.writeText(url);
      alert(t('Registration link copied to clipboard!'));
    } catch (error) {
      alert(t('Failed to copy the registration link.'));
    }
  };

  if (isLoading) {
    return <div className="loader">{t('Loading...')}</div>;
  }

  return (
    <div className="form-container">
      <h2>{t('Invite Form')}</h2>
      <div className="form-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="fields">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => (
                  <Draggable key={field.id} draggableId={String(field.id)} index={index}>
                    {(provided) => (
                      <div
                        className="form-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span>{`${field.field_label} - ${field.field_type}`}</span>
                        <div className="form-item-buttons">
                          <Button variant="secondary" onClick={() => handleEditField(field)}>
                            {t('Edit')}
                          </Button>
                          <Button variant="danger" onClick={() => handleDeleteField(field.id)}>
                            {t('Delete')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button variant="primary" onClick={handleAddField}>
        {t('Add Field')}
      </Button>
      <Button variant="success" onClick={handleGetUrlPress}>
        {t('Share Link')}
      </Button>
      {showFieldModal && (
        <FormFieldModal
          showModal={showFieldModal}
          onClose={handleCloseFieldModal}
          field={editingField}
          eventId={id}
        />
      )}
    </div>
  );
};

export default Form;
