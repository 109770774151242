import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TEAM_URL, EVENTS_URL } from '../../constants/Urls';
import './NewTeamMemberModal.css';

const NewTeamMemberModal = ({ showModal, onClose, eventId, editMember }) => {
  const { t } = useTranslation();
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [canManageEvent, setCanManageEvent] = useState(false);
  const [canManageTasks, setCanManageTasks] = useState(false);
  const [canManageParticipants, setCanManageParticipants] = useState(false);
  const [canManageTeam, setCanManageTeam] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(eventId);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (editMember) {
      setIsRegisteredUser(!!editMember.username);
      setName(editMember.name || '');
      setUsername(editMember.username || '');
      setRole(editMember.role || '');
      setCanManageEvent(editMember.canManageEvent === 1);
      setCanManageTasks(editMember.canManageTasks === 1);
      setCanManageParticipants(editMember.canManageParticipants === 1);
      setCanManageTeam(editMember.canManageTeam === 1);
      setSelectedEventId(editMember.eventId);
    }
  }, [editMember]);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(EVENTS_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const eventOptions = response.data.map(event => ({
        label: `${event.title} - ${event.date}`,
        value: event.id,
      }));
      setEvents(eventOptions);
    } catch (error) {
      console.error('Failed to fetch events:', error);
    }
  };

  const handleSubmit = async () => {
    if (!isRegisteredUser && !name) {
      alert(t('Please fill in the Name field.'));
      return;
    }

    if (isRegisteredUser && !username) {
      alert(t('Please fill in the Username field.'));
      return;
    }

    const bodyData = {
      name,
      role,
      username,
      canManageEvent: canManageEvent ? 1 : 0,
      canManageTasks: canManageTasks ? 1 : 0,
      canManageParticipants: canManageParticipants ? 1 : 0,
      canManageTeam: canManageTeam ? 1 : 0,
      eventId: selectedEventId,
    };

    try {
      const token = localStorage.getItem('token');
      const method = editMember ? 'PUT' : 'POST';
      const url = editMember ? `${TEAM_URL}?id=${editMember.id}` : TEAM_URL;
      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: bodyData,
      });
      if (response.status === 200) {
        alert(t(editMember ? 'Team member updated successfully.' : 'Team member added successfully.'));
        onClose();
      } else {
        alert(t('Failed to save team member. Please try again.'));
      }
    } catch (error) {
      console.error('Failed to save team member:', error);
      alert(t('Failed to save team member. Please try again.'));
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{editMember ? t('Edit Team Member') : t('Add a New Team Member')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t('Select User Type')}</Form.Label>
            <Form.Control
              as="select"
              value={isRegisteredUser ? 'registered' : 'unregistered'}
              onChange={e => setIsRegisteredUser(e.target.value === 'registered')}
              disabled={!!editMember}
            >
              <option value="registered">{t('Registered User')}</option>
              <option value="unregistered">{t('Unregistered User')}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Event')}</Form.Label>
            <Form.Control
              as="select"
              value={selectedEventId}
              onChange={e => setSelectedEventId(e.target.value)}
              disabled={!!editMember}
            >
              <option value="">{t('Select an event')}</option>
              {events.map(event => (
                <option key={event.value} value={event.value}>
                  {event.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {!isRegisteredUser && (
            <Form.Group>
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>
          )}
          {isRegisteredUser && (
            <Form.Group>
              <Form.Label>{t('Username')}</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>{t('Role')}</Form.Label>
            <Form.Control
              type="text"
              value={role}
              onChange={e => setRole(e.target.value)}
            />
          </Form.Group>
          {isRegisteredUser && (
            <>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label={t('Can Manage Event')}
                  checked={canManageEvent}
                  onChange={e => setCanManageEvent(e.target.checked)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label={t('Can Manage Tasks')}
                  checked={canManageTasks}
                  onChange={e => setCanManageTasks(e.target.checked)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label={t('Can Manage Participants')}
                  checked={canManageParticipants}
                  onChange={e => setCanManageParticipants(e.target.checked)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label={t('Can Manage Team')}
                  checked={canManageTeam}
                  onChange={e => setCanManageTeam(e.target.checked)}
                />
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {editMember ? t('Update') : t('Add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTeamMemberModal;
