import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TASKS_URL, TASK_ORDER_URL } from '../../constants/Urls';
import './NewTaskModal.css';

const NewTaskModal = ({ showModal, onClose, task, eventId, teamMembers = [] }) => {
  const { t } = useTranslation();
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [amount, setAmount] = useState('');
  const [priority, setPriority] = useState('Medium');
  const [status, setStatus] = useState('New');
  const [assignee, setAssignee] = useState('Unassigned');
  const [selectedColor, setSelectedColor] = useState('#333');
  const [taskType, setTaskType] = useState('task');
  const isEditing = !!task;
  let orderNumber = 0;

  useEffect(() => {
    if (task) {
      setTaskName(task.taskName);
      setDescription(task.description);
      setDueDate(task.date || '');
      setAmount(task.amount || '');
      setPriority(task.priority || 'Medium');
      setStatus(task.status || 'New');
      setAssignee(task.assignee || 'Unassigned');
      setSelectedColor(task.color || '#333');
      setTaskType(task.type || 'task');
    } else {
      resetForm();
    }
  }, [task]);

  useEffect(() => {
    if (!isEditing) {
      getLatestOrderId();
    }
  }, [isEditing, eventId]);

  const resetForm = () => {
    setTaskName('');
    setDescription('');
    setDueDate('');
    setAmount('');
    setPriority('Medium');
    setStatus('New');
    setAssignee('Unassigned');
    setSelectedColor('#333');
    setTaskType('task');
  };

  const getLatestOrderId = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${TASK_ORDER_URL}?id=${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      orderNumber = response.data[0]?.latestOrderId + 1 || 1;
    } catch (error) {
      console.error('Error fetching latest order ID:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  const handleTaskOperation = async () => {
    if (isEditing) {
      await handleEditTask();
    } else {
      await handleAddTask();
    }
  };

  const handleAddTask = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        TASKS_URL,
        {
          taskName,
          description,
          date: dueDate,
          amount: parseFloat(amount),
          priority,
          status,
          type: taskType,
          orderId: orderNumber,
          teamMemberId: assignee !== 'Unassigned' ? teamMembers.find(tm => tm.name === assignee).id : null,
          eventId,
          color: selectedColor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        onClose();
      } else {
        alert(t('Failed to add task. Please try again.'));
      }
    } catch (error) {
      console.error('Error adding task:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  const handleEditTask = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${TASKS_URL}?id=${task.id}`,
        {
          taskName,
          description,
          date: dueDate,
          amount: parseFloat(amount),
          priority,
          status,
          type: taskType,
          teamMemberId: assignee !== 'Unassigned' ? teamMembers.find(tm => tm.name === assignee).id : null,
          eventId,
          color: selectedColor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        onClose();
      } else {
        alert(t('Failed to update task. Please try again.'));
      }
    } catch (error) {
      console.error('Error updating task:', error);
      alert(t('Error') + ': ' + error.message);
    }
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? t('Edit Task') : t('Add Task')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t('Task Name')}</Form.Label>
            <Form.Control
              type="text"
              value={taskName}
              onChange={e => setTaskName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('Type')}</Form.Label>
            <Form.Control
              as="select"
              value={taskType}
              onChange={e => setTaskType(e.target.value)}
            >
              <option value="task">{t('Task')}</option>
              <option value="section">{t('Section')}</option>
            </Form.Control>
          </Form.Group>
          {taskType === 'task' && (
            <>
              <Form.Group>
                <Form.Label>{t('Description')}</Form.Label>
                <Form.Control
                  as="textarea"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Due Date')}</Form.Label>
                <Form.Control
                  type="date"
                  value={dueDate}
                  onChange={e => setDueDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Amount')}</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Priority')}</Form.Label>
                <Form.Control
                  as="select"
                  value={priority}
                  onChange={e => setPriority(e.target.value)}
                >
                  <option value="Low">{t('Low')}</option>
                  <option value="Medium">{t('Medium')}</option>
                  <option value="High">{t('High')}</option>
                  <option value="Critical">{t('Critical')}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Status')}</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option value="New">{t('New')}</option>
                  <option value="In Progress">{t('In Progress')}</option>
                  <option value="Done">{t('Done')}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('Assignee')}</Form.Label>
                <Form.Control
                  as="select"
                  value={assignee}
                  onChange={e => setAssignee(e.target.value)}
                >
                  <option value="Unassigned">{t('Unassigned')}</option>
                  {teamMembers.map(tm => (
                    <option key={tm.id} value={tm.name}>
                      {tm.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}
          <Form.Group>
            <Form.Label>{t('Color')}</Form.Label>
            <Form.Control
              type="color"
              value={selectedColor}
              onChange={e => setSelectedColor(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t('Close')}
        </Button>
        <Button variant="primary" onClick={handleTaskOperation}>
          {isEditing ? t('Save Changes') : t('Add Task')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTaskModal;
