import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from './pages/Login';
import LoginForm from './pages/login/LoginForm';
import AllTasks from './pages/tasks/AllTasks';
import Guests from './pages/guests/Guests';
import Team from './pages/team/Team';
import './i18n';
import EventDetail from './pages/events/EventDetail';
import Events from './pages/events/Events';
import RegistrationForm from './pages/login/RegistrationForm';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/profile/EditProfile';
import ProfileDetails from './pages/profile/ProfileDetails';
import TopMenu from './components/TopMenu';
import Settings from './pages/settings/Settings';
import Language from './pages/settings/Language';
import Templates from './pages/templates/Templates';
import ViewTemplate from './pages/templates/ViewTemplate';
import Users from './pages/users/Users';
import Notifications from './pages/notifications/Notifications';
import Conversations from './pages/messages/Conversations';
import Messages from './pages/messages/Messages';
import EventRegistrationForm from './pages/guests/EventRegistrationForm';
import ResetPassword from './pages/settings/ResetPassword';
import ContactUsModal from './pages/contactUs/ContactUsModal';
import NotFoundPage from './pages/errors/NotFoundPage';

const AppWrapper = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/login-form' && 
       location.pathname !== '/registration' &&
       location.pathname !== '/resetPassword' &&
       location.pathname !== '/404' &&
       !location.pathname.startsWith('/event-form/') && <TopMenu />}
       
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login-form" element={<LoginForm />} />
        <Route path="/events" element={<Events />} />
        <Route path="/all-tasks" element={<AllTasks />} />
        <Route path="/event/:id/*" element={<EventDetail />} />
        <Route path="/registration" element={<RegistrationForm />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/language" element={<Language />} />
        <Route path="/profile-details" element={<ProfileDetails />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/users" element={<Users />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/messages" element={<Conversations />} />
        <Route path="/messages/:conversationId" element={<Messages />} />
        <Route path="/view-template/:id/*" element={<ViewTemplate />} />
        <Route path="/" element={<Login />} />
        <Route path="/event-form/:eventId" element={<EventRegistrationForm />} />
        <Route path="/event-form/:eventId/:guestId" element={<EventRegistrationForm />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

function App() {
  const { t } = useTranslation();

  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;
