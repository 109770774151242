import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FORM_URL, CHECKLIST_RESPONSES_URL } from '../../constants/Urls';
import './EventRegistrationForm.css';

const EventRegistrationForm = () => {
  const { t, i18n } = useTranslation();
  const { eventId: eventName, guestId } = useParams();
  const [blocks, setBlocks] = useState([]);
  const [responses, setResponses] = useState({});
  const [checklistResponses, setChecklistResponses] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState(''); // Store the name separately
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [companions, setCompanions] = useState([]);
  const [companionsCount, setCompanionsCount] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (eventName) {
      fetchInviteForm(eventName);
      if (guestId) {
        fetchGuestResponses(eventName, guestId);
        fetchChecklistResponses(eventName, guestId);
      }
    } else {
      alert(t('Event name is missing!'));
    }
  }, [eventName, guestId]);

  const fetchInviteForm = async (eventName) => {
    try {
      setLoading(true);
      const response = await axios.get(`${FORM_URL}?event=${eventName}`);
      if (response.data.success) {
        setBlocks(response.data.blocks);
        setEventTitle(response.data.event_title);
        setIsActive(response.data.is_active === 1);
        setEventDate(response.data.date);
        setEventTime(response.data.time);
        setEventLocation(response.data.location);
        setCompanionsCount(response.data.companions_count || 1); // Set companions count from the response
      } else {
        alert(t('Failed to load form fields'));
      }
    } catch (error) {
      alert(t('Failed to load form fields'));
    } finally {
      setLoading(false);
    }
  };

  const fetchGuestResponses = async (eventName, guestId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${FORM_URL}/responses?event=${eventName}&guest=${guestId}`);
      if (response.data.success) {
        const guestResponses = response.data.responses || [];
        if (guestResponses.length === 0) {
          navigate('/404', { state: { message: t('Guest not found') } });
          return;
        }

        // Assuming response structure includes companions
        const mainGuest = guestResponses.find(response => response.participant_id === guestId);
        if (mainGuest) {
          setName(mainGuest.name || '');
          setEmail(mainGuest.email || '');
          setCompanions(mainGuest.companions || []);
          const responseMap = mainGuest.responses.reduce((acc, item) => {
            acc[item.form_field_id] = item.response;
            return acc;
          }, {});
          setResponses(responseMap);
        }
      } else {
        alert(t('Failed to load guest responses'));
      }
    } catch (error) {
      alert(t('Failed to load guest responses'));
    } finally {
      setLoading(false);
    }
  };

  const fetchChecklistResponses = async (eventName, guestId) => {
    try {
      const response = await axios.get(`${CHECKLIST_RESPONSES_URL}?event=${eventName}&guest_id=${guestId}`);
      if (response.data.success) {
        setChecklistResponses(response.data.completed_items.map(item => item.checklist_item_id));
      } else {
        alert(t('Failed to load checklist responses'));
      }
    } catch (error) {
      alert(t('Failed to load checklist responses'));
    }
  };

  const handleChecklistChange = async (checklistItemId) => {
    try {
      const isCompleted = checklistResponses.includes(checklistItemId);
      const updatedResponses = isCompleted
        ? checklistResponses.filter(id => id !== checklistItemId)
        : [...checklistResponses, checklistItemId];

      setChecklistResponses(updatedResponses);

      await axios.post(`${CHECKLIST_RESPONSES_URL}`, {
        event: eventName,
        guest_id: guestId,
        checklist_item_id: checklistItemId,
        completed: !isCompleted,
      });
    } catch (error) {
      alert(t('Failed to update checklist item'));
    }
  };

  const handleInputChange = (e, fieldId) => {
    const { type, value } = e.target;
    setResponses({ ...responses, [fieldId]: value });

    if (type === 'email') {
      setEmail(value);
    }
  };

  const handleCheckboxChange = (e, fieldId) => {
    const updatedResponses = { ...responses };
    if (updatedResponses[fieldId]?.includes(e.target.value)) {
      updatedResponses[fieldId] = updatedResponses[fieldId].filter((v) => v !== e.target.value);
    } else {
      updatedResponses[fieldId] = [...(updatedResponses[fieldId] || []), e.target.value];
    }
    setResponses(updatedResponses);
  };

  const handleRadioChange = (e, fieldId) => {
    setResponses({ ...responses, [fieldId]: e.target.value });
  };

  const handleAddCompanion = () => {
    if (companions.length < companionsCount - 1) {
      setCompanions([...companions, { name: '', type: 'companion' }]);
    }
  };

  const handleRemoveCompanion = (index) => {
    const newCompanions = companions.filter((_, i) => i !== index);
    setCompanions(newCompanions);
  };

  const handleCompanionChange = (index, key, value) => {
    const newCompanions = companions.map((companion, i) => {
      if (i === index) {
        return { ...companion, [key]: value };
      }
      return companion;
    });
    setCompanions(newCompanions);
  };

  const validateFields = () => {
    for (let block of blocks) {
      if (block.block_type === 'registration') {
        for (let field of block.fields) {
          const value = responses[field.id];
          if (field.required === 1) {
            if (
              field.field_type === 'text' ||
              field.field_type === 'email' ||
              field.field_type === 'phone' ||
              field.field_type === 'name'
            ) {
              if (!value || value.trim() === '') {
                return {
                  valid: false,
                  message: `${t(field.field_label)} is required.`,
                };
              }
            } else if (field.field_type === 'checkbox') {
              if (!value || value.length === 0) {
                return {
                  valid: false,
                  message: `At least one option for ${t(field.field_label)} is required.`,
                };
              }
            } else if (field.field_type === 'radio') {
              if (!value) {
                return {
                  valid: false,
                  message: `An option for ${t(field.field_label)} is required.`,
                };
              }
            }
          }
        }
      }
    }
    return { valid: true, message: '' };
  };

  const handleSubmit = async () => {
    const { valid, message } = validateFields();
    if (!valid) {
      alert(message);
      return;
    }

    const participantId = generateUUIDv4();
    const lang = i18n.language;

    // Name is taken from the state directly
    if (!name) {
      alert(t('Name is required.'));
      return;
    }

    if (!email) {
      alert(t('Email is required.'));
      return;
    }

    const payload = {
      form_name: eventName,
      participant_id: participantId,
      email: email, // Email is sent as part of the payload
      name: name, // Name is sent as part of the payload
      type: 'guest',
      additional_guests: companions,
      responses: responses,
      lang,
    };

    try {
      const response = await axios({
        method: 'POST',
        url: FORM_URL,
        headers: {
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      if (response.data.success) {
        navigate(`/event-form/${eventName}/${participantId}`);
      } else {
        alert(t('Submission failed') + ': ' + t(response.data.message));
      }
    } catch (error) {
      alert(error);
    }
  };

  const generateUUIDv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const renderBlockContent = (block) => {
    const formatTime = (timeString) => {
      const [hour, minute] = timeString.split(':');
      return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    };

    const isReadOnly = !!guestId;

    switch (block.block_type) {
      case 'agenda':
        return (
          <section key={block.id} className="landing-section">
            <h2>{t(block.title)}</h2>
            <ul>
              {block.content.map((item) => (
                <li key={item.id}>
                  <strong>{formatTime(item.start_time)} - {formatTime(item.end_time)}</strong>: {item.agenda_item}
                </li>
              ))}
            </ul>
          </section>
        );
      case 'event_team':
        return (
          <section key={block.id} className="landing-section">
            <h2>{t(block.title)}</h2>
            <ul className="team-list">
              {block.content.map((member) => (
                <li key={member.id} className="team-member">
                  <img
                    src={member.profile_picture || require('../../assets/default-profile.png')}
                    alt={member.name}
                    className="team-member-avatar"
                  />
                  <div className="team-member-info">
                    <strong>{member.name}</strong>
                    <div>{member.role}</div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        );
      case 'registration':
        return (
          <section key={block.id} className="landing-section">
            <h2>{t(block.title)}</h2>
            {block.description && <p>{block.description}</p>}
            <Form id="registrationForm" className="registration-block">
              {/* Name input field */}
              <Form.Group className="position-relative">
                <Form.Label>
                  {t('Name')}
                  <span className="text-danger"> *</span>
                </Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    value={name} // Value is taken from the state
                    onChange={(e) => setName(e.target.value)} // Set the name value
                    required
                    readOnly={isReadOnly}
                    disabled={isReadOnly} // Disable input when read-only
                    className="flex-grow-1"
                  />
                  {!isReadOnly && companions.length < companionsCount - 1 && (
                    <Button
                      variant="secondary"
                      onClick={handleAddCompanion}
                      className="add-companion-btn"
                    >
                      {t('Add Companion')}
                    </Button>
                  )}
                </div>

                {/* Render companions below the name field */}
                {companions.map((companion, index) => (
                  <Form.Group key={index} className="mt-3 position-relative">
                    <Form.Label>
                      {t('Companion Name')}
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <div className="d-flex align-items-center companion-field">
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="text"
                          value={companion.name}
                          onChange={(e) => handleCompanionChange(index, 'name', e.target.value)}
                          required
                          readOnly={isReadOnly}
                          disabled={isReadOnly} // Disable input when read-only
                          className="flex-grow-1 me-2"
                        />
                        <Form.Check
                          type="checkbox"
                          label={t('Is Child?')}
                          checked={companion.type === 'child'}
                          onChange={(e) =>
                            handleCompanionChange(index, 'type', e.target.checked ? 'child' : 'companion')
                          }
                          className="me-2"
                          disabled={isReadOnly} // Disable checkbox when read-only
                        />
                        {!isReadOnly && (
                          <i
                            className="fas fa-times text-danger remove-companion-icon"
                            onClick={() => handleRemoveCompanion(index)}
                            style={{ cursor: 'pointer' }}
                          ></i>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                ))}
              </Form.Group>

              {/* Email input field */}
              <Form.Group className="position-relative">
                <Form.Label>
                  {t('Email')}
                  <span className="text-danger"> *</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email} // Email value is taken from the state
                  onChange={(e) => setEmail(e.target.value)} // Set the email value
                  required
                  readOnly={isReadOnly}
                  disabled={isReadOnly} // Disable input when read-only
                />
              </Form.Group>

              {block.fields.map((field) => (
                <Form.Group key={field.id} className="position-relative">
                  <Form.Label>
                    {t(field.field_label)}
                    {field.required === 1 && <span className="text-danger"> *</span>}
                  </Form.Label>
                  {field.field_type === 'text' ||
                  field.field_type === 'phone' ? (
                    <Form.Control
                      type={field.field_type}
                      value={responses[field.id] || ''}
                      onChange={(e) => handleInputChange(e, field.id)}
                      required={field.required === 1}
                      readOnly={isReadOnly}
                      disabled={isReadOnly} // Disable input when read-only
                    />
                  ) : field.field_type === 'checkbox' ? (
                    JSON.parse(field.field_options).map((option, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={option}
                        value={option}
                        checked={responses[field.id]?.includes(option) || false}
                        onChange={(e) => handleCheckboxChange(e, field.id)}
                        required={field.required === 1 && index === 0}
                        disabled={isReadOnly} // Disable checkbox when read-only
                      />
                    ))
                  ) : field.field_type === 'radio' ? (
                    JSON.parse(field.field_options).map((option, index) => (
                      <Form.Check
                        key={index}
                        type="radio"
                        label={option}
                        name={`radio-${field.id}`}
                        value={option}
                        checked={responses[field.id] === option}
                        onChange={(e) => handleRadioChange(e, field.id)}
                        required={field.required === 1}
                        disabled={isReadOnly} // Disable radio when read-only
                      />
                    ))
                  ) : null}
                </Form.Group>
              ))}
            </Form>
            {!isReadOnly && (
              <Button variant="primary" type="button" onClick={handleSubmit} className="mt-3">
                {t('Register')}
              </Button>
            )}
            {responseMessage && (
              <div
                className="response-message"
                dangerouslySetInnerHTML={{ __html: responseMessage }}
              />
            )}
          </section>
        );
      case 'contact_point':
        return (
          <section key={block.id} className="landing-section">
            <h2>{t(block.title)}</h2>
            <ul className="contact-points-list">
              {block.content.sort((a, b) => a.order_id - b.order_id).map((contact) => (
                <li key={contact.id} className="contact-point">
                  <div className="contact-name"><strong>{contact.contact_name}</strong></div>
                  <div className="contact-role"><strong>{t('Role')}:</strong> {contact.contact_role}</div>
                  <div className="contact-phone"><strong>{t('Phone')}:</strong> {contact.phone}</div>
                  <div className="contact-email"><strong>{t('Email')}:</strong> {contact.email}</div>
                </li>
              ))}
            </ul>
          </section>
        );
      case 'guest_checklist':
        if (!guestId) return null;
        return (
          <section key={block.id} className="landing-section">
            <h2>{t(block.title)}</h2>
            {block.content.map((item) => (
              <Form.Check
                key={item.id}
                type="checkbox"
                label={item.item_text}
                checked={checklistResponses.includes(item.id)}
                onChange={() => handleChecklistChange(item.id)}
              />
            ))}
          </section>
        );
      case 'header':
        return (
          <section key={block.id} className="landing-section-header">
            <h2>{t(block.title)}</h2>
          </section>
        );
      case 'paragraph':
        return (
          <section key={block.id} className="landing-section">
            <p>{t(block.title)}</p>
          </section>
        );
      default:
        return null;
    }
  };

  return (
    <div className="landing-container">
      {eventTitle && <h1 className="landing-title">{eventTitle}</h1>}
      {guestId && (
          <p className="event-registration-success">{t('You are successfully registered for this event')}</p>
      )}
      {/* Display event metadata */}
      <div className="event-metadata">
        {eventDate && eventTime && (
          <div className="event-date-time">
            <p className="event-date">{t('Date')}: {eventDate}</p>
            <p className="event-time">{t('Time')}: {eventTime}</p>
          </div>
        )}
        {eventLocation && <p className="event-location">{t('Location')}: {eventLocation}</p>}
      </div>
      {!isActive ? (
        <p className="landing-closed-text">{t('Event registration is closed.')}</p>
      ) : (
        <>
          {loading ? (
            <div className="loader">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('Loading...')}</span>
              </Spinner>
            </div>
          ) : (
            blocks.map((block) => renderBlockContent(block))
          )}
          {responseMessage && (
            <div
              className="response-message"
              dangerouslySetInnerHTML={{ __html: responseMessage }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EventRegistrationForm;
