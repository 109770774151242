import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './SideBar.css';

const Sidebar = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Nav className="flex-column sidebar">
      <Nav.Item>
        <Nav.Link as={Link} to={`/event/${id}`}>{t("Details")}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to={`/event/${id}/tasks`}>{t("Tasks")}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to={`/event/${id}/team`}>{t("Team")}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to={`/event/${id}/guests`}>{t("Guests")}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to={`/event/${id}/form`}>{t("Form")}</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Sidebar;
