import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { EVENTS_URL } from '../../constants/Urls';
import Sidebar from '../../components/SideBar';
import './EventDetail.css';
import Tasks from '../tasks/Tasks';
import Team from '../team/Team';
import Guests from '../guests/Guests';
import Form from '../form/Form';
import EventModal from './EventModal';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEvent = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${EVENTS_URL}?id=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setEvent(response.data);
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
        alert(t('Error') + ': ' + error.message);
      }
    };

    fetchEvent();
  }, [id, t]);

  const handleEventUpdated = (updatedEvent) => {
    setEvent(updatedEvent);
    setShowModal(false);
  };

  if (!event) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className="event-detail-container">
      <Sidebar />
      <div className="event-content">
        <h2>{event.title}</h2>      
        <Button variant="primary" onClick={() => setShowModal(true)}>
          {t('Edit Event')}
        </Button>
        <Routes>
          <Route path="/" element={<EventDetails event={event} />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/team" element={<Team />} />
          <Route path="/guests" element={<Guests />} />
          <Route path="/form" element={<Form />} />
        </Routes>
      </div>
      {showModal && (
        <EventModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          event={event}
          onEventUpdated={handleEventUpdated}
        />
      )}
    </div>
  );
};

const EventDetails = ({ event }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="detail-item">
        <strong>{t('Date')}:</strong> <span>{event.date}</span>
      </div>
      {event.time && (
        <div className="detail-item">
          <strong>{t('Time')}:</strong> <span>{event.time}</span>
        </div>
      )}
      {event.budget && (
        <div className="detail-item">
          <strong>{t('Budget')}:</strong> <span>{event.budget}</span>
        </div>
      )}
      {event.currency && (
        <div className="detail-item">
          <strong>{t('Currency')}:</strong> <span>{event.currency}</span>
        </div>
      )}
      {event.type && (
        <div className="detail-item">
          <strong>{t('Type')}:</strong> <span>{event.type}</span>
        </div>
      )}
      {event.location && (
        <div className="detail-item">
          <strong>{t('Location')}:</strong> <span>{event.location}</span>
        </div>
      )}
      {event.description && (
        <div className="detail-item">
          <strong>{t('Description')}:</strong> <span>{event.description}</span>
        </div>
      )}
    </>
  );
};

export default EventDetail;
