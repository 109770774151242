import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PROFILE_URL, UPLOAD_PROFILE_PICTURE_URL } from '../../constants/Urls';
import './EditProfile.css';
import countriesData from '../../resources/countries.json';
import specializationsData from '../../resources/specializations.json';

const EditProfile = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cities, setCities] = useState([]);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [specializations, setSpecializations] = useState(['']);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const country = countriesData.find(c => c.name === selectedCountry);
      setCities(country ? country.cities : []);
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(PROFILE_URL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data) {
      setUsername(data.username);
      setEmail(data.email);
      setName(data.name);
      setDescription(data.description);
      setSelectedCountry(data.country);
      setSelectedCity(data.city);
      setIsSpecialist(data.is_specialist === 1);
      setSpecializations(
        data.specializations.length > 0 ? data.specializations : ['']
      );
      setProfilePicture(data.profile_picture);
    }
    setIsLoading(false);
  };

  const handleUpdateProfile = async () => {
    const token = localStorage.getItem('token');
    if (!name || !email) {
      alert(t('Please fill in all required fields.'));
      return;
    }

    const selectedSpecializations = specializations.filter(spec => spec !== '');

    const response = await fetch(PROFILE_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        username,
        email,
        name,
        country: selectedCountry,
        city: selectedCity,
        is_specialist: isSpecialist ? 1 : 0,
        specializations: selectedSpecializations,
        description,
      }),
    });

    if (response.ok) {
      alert(t('Profile updated successfully'));
      navigate('/profile');
    } else {
      const data = await response.json();
      alert(`${t('Failed to update profile:')} ${t(data.error)}`);
    }
  };

  const addSpecializationField = () => {
    setSpecializations([...specializations, '']);
  };

  const removeSpecializationField = index => {
    setSpecializations(specializations.filter((_, i) => i !== index));
  };

  const updateSpecialization = (value, index) => {
    const updatedSpecializations = specializations.map((spec, i) =>
      i === index ? value : spec
    );
    setSpecializations(updatedSpecializations);
  };

  const handleProfilePictureUpload = async e => {
    const file = e.target.files[0];
    if (file) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('profile_picture', file);

      const response = await fetch(UPLOAD_PROFILE_PICTURE_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setProfilePicture(data.profile_picture);
        alert(t('Profile picture updated successfully'));
      } else {
        const data = await response.json();
        alert(`${t('Failed to update profile picture:')} ${t(data.error)}`);
      }
    }
  };

  if (isLoading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-header">
        <div className="profile-picture-container">
          <img
            src={
              profilePicture
                ? profilePicture
                : require('../../assets/default-profile.png')
            }
            alt="Profile"
            className="profile-picture"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePictureUpload}
            className="upload-input"
          />
        </div>
      </div>
      <div className="edit-profile-form">
        <div className="form-group">
          <label>{t('Username')}</label>
          <input
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
            placeholder={t('Username')}
          />
        </div>
        <div className="form-group">
          <label>{t('Name')}</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('Name')}
          />
        </div>
        <div className="form-group">
          <label>{t('Email')}</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={t('Email')}
          />
        </div>
        <div className="form-group">
          <label>{t('Profile description')}</label>
          <input
            type="text"
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={t('Profile description')}
          />
        </div>
        <div className="form-group">
          <label>{t('Country')}</label>
          <select
            value={selectedCountry}
            onChange={e => setSelectedCountry(e.target.value)}
          >
            <option value="">{t('Select a country')}</option>
            {countriesData.map(country => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>{t('City')}</label>
          <select
            value={selectedCity}
            onChange={e => setSelectedCity(e.target.value)}
            disabled={!selectedCountry}
          >
            <option value="">{t('Select a city')}</option>
            {cities.map(city => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>{t('Are you a specialist?')}</label>
          <input
            type="checkbox"
            checked={isSpecialist}
            onChange={e => setIsSpecialist(e.target.checked)}
          />
        </div>
        {isSpecialist &&
          specializations.map((specialization, index) => (
            <div key={index} className="form-group specialization-group">
              <select
                value={specialization}
                onChange={e => updateSpecialization(e.target.value, index)}
              >
                <option value="">{t('Select a specialization')}</option>
                {specializationsData.map(spec => (
                  <option key={spec} value={spec}>
                    {spec}
                  </option>
                ))}
              </select>
              {specializations.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeSpecializationField(index)}
                >
                  -
                </button>
              )}
            </div>
          ))}
        {isSpecialist && (
          <button
            type="button"
            onClick={addSpecializationField}
            className="add-button"
          >
            +
          </button>
        )}
        <button onClick={handleUpdateProfile} className="update-button">
          {t('Update Profile')}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
