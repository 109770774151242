import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PROFILE_URL, FAVORITE_URL } from '../../constants/Urls';
import './Profile.css';

const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const url = userId ? `${PROFILE_URL}?id=${userId}` : `${PROFILE_URL}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUser(data);
      setIsFavorite(data.is_favorite);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };

  const toggleFavorite = async () => {
    try {
      const token = localStorage.getItem('token');
      const method = isFavorite ? 'DELETE' : 'POST';
      const response = await fetch(`${FAVORITE_URL}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ favorite_user_id: userId }),
      });

      if (response.ok) {
        setIsFavorite(!isFavorite);
      } else {
        console.error('Failed to toggle favorite:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to toggle favorite:', error);
    }
  };

  if (isLoading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-image-container">
          <img
            src={
              user.profile_picture
                ? user.profile_picture
                : require('../../assets/default-profile.png')
            }
            alt="Profile"
            className="profile-picture"
          />
        </div>
        <h2 className="profile-name">{user.name}</h2>
        <div className="profile-buttons">
          {userId && (
            <button onClick={toggleFavorite} className="favorite-button">
              {isFavorite ? '★' : '☆'}
            </button>
          )}
          {!userId && (
            <button onClick={() => navigate('/edit-profile')} className="edit-button">
              Edit
            </button>
          )}
        </div>
      </div>
      {user.username && (
        <div className="profile-info">
          <strong>{t('Username')}:</strong> {user.username}
        </div>
      )}
      {user.country && (
        <div className="profile-info">
          <strong>{t('Country')}:</strong> {user.country}
        </div>
      )}
      {user.city && (
        <div className="profile-info">
          <strong>{t('City')}:</strong> {user.city}
        </div>
      )}
      {user.specializations && user.specializations.length > 0 && (
        <div className="profile-info">
          <strong>{t('Specializations')}:</strong> {user.specializations.join(', ')}
        </div>
      )}
      {user.description && (
        <div className="profile-info">
          <strong>{t('Profile description')}:</strong> {user.description}
        </div>
      )}
    </div>
  );
};

export default Profile;
